import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { FormTextInput } from "../../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { accountDeleteService, authenticationManager } from "../../../../../shared/core/service/services";
import { ACCOUNT_DELETE_MODAL_ID, PINCODE_MUST_BE_CHANGED_MODAL_ID } from "../../../../core/modal/modal-id";
import { Path } from "../../../../core/routing/path";
import { useRTL } from "../../../../domain/language/use-rtl";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { TextField } from "../../../common/data-forms/text-field";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { IconCommunication } from "../../../common/svg/icon-communication";
import { IconKeypad } from "../../../common/svg/icon-keypad";
import { theme } from "../../../styles/theme";
import { PincodeChangeModal } from "../../pincode/pincode-change-modal";
import { OptionCard } from "./option-card";

enum DeletionStep {
  Warning,
  Confirm,
}

const ModalContent = ({
  step,
  formatMessage,
  goToContact,
  dismissModal,
  setStep,
  reason,
  setReason,
  handleDeleteAccount,
  feedback,
}) => {
  if (step === DeletionStep.Warning) {
    return (
      <>
        <Title>{formatMessage("accountDeleteScreen.title")}</Title>
        <Subtitle>{formatMessage("accountDeleteScreen.subtitle")}</Subtitle>
        <OptionsContainer>
          <OptionCard
            title={formatMessage("accountDeleteScreen.options.changeProfile.title")}
            subtitle={formatMessage("accountDeleteScreen.options.changeProfile.description")}
            onClick={() => {
              dismissModal();
              Modal.present(PINCODE_MUST_BE_CHANGED_MODAL_ID, () => <PincodeChangeModal type="skip-explanation" />, {
                canBeDismissed: false,
              });
            }}
          >
            <IconKeypad color={theme.mainColor()} />
          </OptionCard>
          <OptionCard
            title={formatMessage("accountDeleteScreen.options.unHappy.title")}
            subtitle={formatMessage("accountDeleteScreen.options.unHappy.description")}
            onClick={goToContact}
          >
            <IconCommunication color={theme.mainColor()} />
          </OptionCard>
        </OptionsContainer>
        <TextButton onClick={() => setStep(DeletionStep.Confirm)}>
          {formatMessage("accountDeleteScreen.button")}
        </TextButton>
        <Explanation>{formatMessage("accountDeleteScreen.explanation")}</Explanation>
      </>
    );
  }

  return (
    <>
      <StyledTitle>{formatMessage("accountDeleteScreen.confirm.title")}</StyledTitle>
      <TextField
        innerStyle={{ paddingBottom: 40 }}
        input={reason}
        placeholder={formatMessage("accountDeleteScreen.confirm.placeholder")}
        onChange={(e) => setReason({ ...reason, value: e })}
        multiline
      />
      {feedback.success || feedback.error ? (
        <Subtitle
          style={{ color: feedback.success ? theme.colors.success[500] : theme.colors.error[500], marginTop: 28 }}
        >
          {feedback.success || feedback.error}
        </Subtitle>
      ) : (
        <>
          <StyledSubtitle>{formatMessage("accountDeleteScreen.confirm.subtitle")}</StyledSubtitle>
          <Warning>{formatMessage("accountDeleteScreen.confirm.warning")}</Warning>
        </>
      )}
      <StyledPrimaryButton size="S" onClick={handleDeleteAccount} disabled={!reason.value}>
        {formatMessage("accountDeleteScreen.confirm.button")}
      </StyledPrimaryButton>
    </>
  );
};

export const AccountDeleteModal = () => {
  const { formatMessage } = useIntl();
  const dismissModal = () => Modal.dismiss(ACCOUNT_DELETE_MODAL_ID);
  const { isRTL } = useRTL();
  const history = useHistory();
  const [step, setStep] = useState<DeletionStep>(DeletionStep.Warning);
  const [feedback, setFeedback] = useState<{ success: string; error: string }>({ success: "", error: "" });
  const [reason, setReason] = useState<FormTextInput>({ id: "reason", value: "" });

  const goToContact = useCallback(() => {
    history.replace(Path.BankContact);
    dismissModal();
  }, [history]);

  const handleDeleteAccount = useCallback(() => {
    const session = authenticationManager.session.get();
    setFeedback({ success: "", error: "" });

    if (session.clientId && reason.value) {
      accountDeleteService
        .deleteAccount(session.clientId, reason.value)
        .then(() => {
          setFeedback({ success: formatMessage("accountDeleteScreen.confirm.success"), error: "" });
        })
        .catch((err) => {
          setFeedback({ success: "", error: err.message || "An error occurred" });
        });
    }
  }, [reason, formatMessage]);

  return (
    <ModalContainer closeButton id={ACCOUNT_DELETE_MODAL_ID} className="delete-account-modal-container">
      <TopRightBackgroundImage $isRTL={isRTL} />
      <ModalContent
        step={step}
        formatMessage={formatMessage}
        goToContact={goToContact}
        dismissModal={dismissModal}
        setStep={setStep}
        reason={reason}
        setReason={setReason}
        handleDeleteAccount={handleDeleteAccount}
        feedback={feedback}
      />
    </ModalContainer>
  );
};

const ModalContainer = styled(RoundedModalContainer)`
  &.delete-account-modal-container {
    margin-top: 5vh;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 36px 20px;
  min-width: 400px;
`;

const BackgroundSrc = require("../../../../assets/images/svg/delete-account-background.svg");

const TopRightBackgroundImage = styled.div<{ $isRTL: boolean }>`
  position: absolute;
  top: 0;
  right: ${(props) => (props.$isRTL ? "unset" : 0)};
  left: ${(props) => (props.$isRTL ? 0 : "unset")};
  background-image: url(${BackgroundSrc});
  width: 263px;
  height: 190px;
  -webkit-transform: scaleX(${(props) => (props.$isRTL ? -1 : 1)});
  transform: scaleX(${(props) => (props.$isRTL ? -1 : 1)});
`;

const Title = styled.div`
  ${theme.boldText};
  color: black;
  font-size: 1.5rem;
  line-height: 2.125rem;
  text-align: center;
  margin: 86px 0 12px;
`;

const Subtitle = styled.div`
  ${theme.text};
  color: #b1b1b1;
  font-size: 0.9375rem;
  line-height: 1.4375rem;
  text-align: center;
  margin: 8px 50px 16px;
`;

const Warning = styled.div`
  ${theme.boldText};
  color: #000000;
  font-size: 1.0375rem;
  line-height: 1.4375rem;
  text-align: center;
  margin: 8px 50px 16px;
`;

const TextButton = styled.div`
  ${theme.boldText};
  text-decoration: underline;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  margin: 32px 66px 22px;
`;

const Explanation = styled.div`
  ${theme.text};
  color: #aaaaaa;
  font-size: 0.8125rem;
  line-height: 1.3125rem;
  text-align: center;
  margin: 20px 66px 16px;
`;

const OptionsContainer = styled.div``;

const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 38px auto 24px;
`;

const StyledSubtitle = styled(Subtitle)`
  margin-top: 28px;
  margin-bottom: 20px;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
  font-size: 20px;
`;
