import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { transferManager } from "../../../../../shared/core/service/services";
import {
  RefundTransactionReason,
  Transaction,
} from "../../../../../shared/domains/accounting-transaction/transaction/transaction";
import { REFUND_TRANSACTION_REASON_MODAL_ID } from "../../../../core/modal/modal-id";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { ErrorMessage } from "../../../common/error-message";
import { RadioSelector, RadioSelectorType } from "../../../common/forms/radio-selector";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { shadows, theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";

export interface RefundTransactionReasonModalProps {
  transaction: Transaction;
}

export const RefundTransactionReasonModal = (props: RefundTransactionReasonModalProps) => (
  <RoundedModalContainer closeButton id={REFUND_TRANSACTION_REASON_MODAL_ID}>
    <RefundTransactionCard {...props} />
  </RoundedModalContainer>
);

const RefundTransactionCard: React.FC<any> = (props) => {
  const { transaction, loading, client, className } = props;
  const { formatMessage } = useIntl();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [selectedRefundReason, setSelectedRefundReason] = useState(RefundTransactionReason.AC03);

  useEffect(() => {
    setSelectedRefundReason(RefundTransactionReason.AC03);
  }, [transaction]);

  const opposeReasons = useMemo(
    () =>
      new Map<RefundTransactionReason, string>([
        [RefundTransactionReason.AC03, formatMessage("refundTransactionModal.reasons.AC03")],
        [RefundTransactionReason.AM09, formatMessage("refundTransactionModal.reasons.AM09")],
        [RefundTransactionReason.CUST, formatMessage("refundTransactionModal.reasons.CUST")],
      ]),
    [formatMessage],
  );

  const handleRefundConfirm = async () => {
    //Get the associated P-Transaction using the businessProcessId of the transaction
    if (transaction.businessProcessId) {
      try {
        const response = await transferManager.submitCreditTransferSinglePayment(
          transaction.businessProcessId,
          client,
          selectedRefundReason,
        );
        if (response) {
          setSuccess(formatMessage("refundTransactionModal.success"));
        }
      } catch (err) {
        setError(err);
      }
    }
  };

  return (
    <Container className={className}>
      <MessageLabel>{formatMessage("refundTransactionModal.title")}</MessageLabel>
      <ReasonsContainer>
        <StyledRadioSelector
          innerId="opposition_button"
          values={opposeReasons}
          value={selectedRefundReason}
          onChange={setSelectedRefundReason}
        />
      </ReasonsContainer>
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
      {success && <StyledSuccessMessage>{success}</StyledSuccessMessage>}
      <ConfirmButtonsContainer>
        <PrimaryButton size="S" onClick={() => Modal.dismiss(REFUND_TRANSACTION_REASON_MODAL_ID)}>
          {formatMessage("refundTransactionModal.cancelButton")}
        </PrimaryButton>
        <PrimaryButton showSpinner={loading} size="S" onClick={() => !loading && handleRefundConfirm()}>
          {formatMessage("refundTransactionModal.confirmButton")}
        </PrimaryButton>
      </ConfirmButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #ffffff;
  border-radius: 13px;
  ${shadows.medium};
  padding: 20px;
`;

const ReasonsContainer = styled.div`
  display: flex;
  margin-top: 25px;
  align-self: stretch;
`;

const MessageLabel = styled.span`
  ${theme.mediumText}
  font-size: 18px;
  color: black;
`;

const ConfirmButtonsContainer = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 36px;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    > * {
      width: 100%;
      max-width: 200px;
    }

    > *:not(:first-child) {
      margin-top: 10px;
    }
  }
`;

const StyledRadioSelector = styled<RadioSelectorType<RefundTransactionReason>>(RadioSelector)`
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px 0;
`;
const StyledSuccessMessage = styled(ErrorMessage)`
  margin: 10px 0;
  color: ${theme.colors.success[500]};
`;
