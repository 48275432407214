import { useMemo } from "react";
import { Account } from "./account";
export const useSelectableCurrencies = (accounts: Account[]) => {
  const getAllCurrencies = (accounts: Account[]) => {
    const res = [];
    accounts.map((a) => a.balances.map((b) => res.push(b.currencyAlpha)));
    return res;
  };

  const selectableCurrencies = useMemo(() => new Set(getAllCurrencies(accounts)), [accounts]);

  return selectableCurrencies;
};
