import { CreditScope, SavingsScope, Scope, SharedServiceDomainScope } from "../authentication/scope";
import { Session, sessionHasScope } from "../authentication/session";

import { Config } from "../../core/config/config";
import { Observable } from "../../utils/observable";
import { AuthenticationManager } from "../authentication/authentication-manager";
import { Features } from "./features";

export class FeaturesManager {
  public features: Observable<Features> = new Observable<Features>(new Features());

  public constructor(private authenticationManager: AuthenticationManager) {
    this.updateFeatures(authenticationManager.session.get());
    this.authenticationManager.session.onChange.add((session) => this.updateFeatures(session));
  }

  private updateFeatures(session: Session | null): void {
    const features = {} as Features;
    if (session) {
      features.accounts = sessionHasScope(session, Scope.AccountsView);
      features.payment = sessionHasScope(session, Scope.Payment);
      features.payout = sessionHasScope(session, Scope.Payout);
      features.p2pTransfer = sessionHasScope(session, Scope.P2PTransfer);
      features.p2pSimpleTransfer = sessionHasScope(session, Scope.P2PSimpleTransfer);
      features.p2pCashTransfer = sessionHasScope(session, Scope.P2PCashTransfer);
      features.p2pDedicatedTransfer = sessionHasScope(session, Scope.P2PDedicatedTransfer);
      features.billPayment = sessionHasScope(session, Scope.BillPayment);
      features.transactionCode = sessionHasScope(session, Scope.TransactionCode);
      features.consultLoans = sessionHasScope(session, Scope.ConsultLoans);
      features.subscribeLoan = sessionHasScope(session, Scope.SubscribeLoan);
      features.cards = sessionHasScope(session, Scope.CardsManagement);
      features.virtualCardCreation = sessionHasScope(session, Scope.CardsVirtualCreation);
      features.clientInformationView = sessionHasScope(session, Scope.KYCView);
      features.clientInformationUpdate = sessionHasScope(session, Scope.KYCUpdate);
      features.pincodeUpdate = sessionHasScope(session, Scope.PincodeUpdate);
      features.notifications = sessionHasScope(session, Scope.Notifications);
      features.withdraw = sessionHasScope(session, Scope.Withdraw);
      features.walletRechargeByCard = sessionHasScope(session, Scope.WalletRechargeByCard);
      features.bankToWallet = sessionHasScope(session, Scope.BankToWallet);
      features.acceptorSearch = sessionHasScope(session, Scope.AcceptorSearch);
      features.manageQrCode = sessionHasScope(session, Scope.ManageQrCode);
      features.manageCategorizationSpendings = sessionHasScope(session, Scope.ManageCategorizationSpendings);
      features.bicReferential = sessionHasScope(session, Scope.BicReferential);
      features.externalAccountsManagement = sessionHasScope(session, Scope.ExternalAccountsManagement);
      features.externalAccountsUsage = sessionHasScope(session, Scope.ExternalAccountsUsage);
      features.beneficiariesManagement = sessionHasScope(session, Scope.BeneficiariesManagement);
      features.beneficiariesUsage = sessionHasScope(session, Scope.BeneficiariesUsage);
      features.sdaCustomerOnboardingCreate = sessionHasScope(session, Scope.SdaCustomerOnboardingCreate);
      features.sdaCustomerOnboardingView = sessionHasScope(session, Scope.SdaCustomerOnboardingView);
      features.sdaCustomerOnboardingUpdate = sessionHasScope(session, Scope.SdaCustomerOnboardingUpdate);
      features.sdaCustomerOnboardingSubmit = sessionHasScope(session, Scope.SdaCustomerOnboardingSubmit);
      features.strongCustomerAuthentication = sessionHasScope(session, Scope.StrongCustomerAuthentication);
      features.consultAuthorizationHolds = sessionHasScope(session, Scope.ConsultAuthorizationHolds);
      features.cardSensitiveDataView = sessionHasScope(session, Scope.CardSensitiveDataView);
      // features.sdaCardView = sessionHasScope(session, SharedServiceDomainScope.SdaCardView);
      features.sdaCustomerOnboardingActionsInitiate = sessionHasScope(
        session,
        Scope.SdaCustomerOnboardingActionsInitiate,
      );
      features.identityDocumentView = sessionHasScope(session, Scope.IdentityDocumentView);

      // SERVICE DOMAIN
      features.customerInstructionView = sessionHasScope(session, SharedServiceDomainScope.CustomerInstructionView);
      features.customerInstructionInitiation = sessionHasScope(
        session,
        SharedServiceDomainScope.CustomerInstructionInitiation,
      );
      features.customerInstructionSubmit = sessionHasScope(session, SharedServiceDomainScope.CustomerInstructionSubmit);
      features.customerInstructionUpdate = sessionHasScope(session, SharedServiceDomainScope.CustomerInstructionUpdate);
      features.customerInstructionDeletion = sessionHasScope(
        session,
        SharedServiceDomainScope.CustomerInstructionDeletion,
      );
      features.paymentNetwork = sessionHasScope(session, SharedServiceDomainScope.PaymentNetwork);
      features.paymentContract = sessionHasScope(session, SharedServiceDomainScope.PaymentContract);
      features.sdaAuthenticationsSessionsView = sessionHasScope(
        session,
        SharedServiceDomainScope.SdaAuthenticationsSessionsView,
      );
      features.sdaAuthenticationsSessionsAuth = sessionHasScope(
        session,
        SharedServiceDomainScope.SdaAuthenticationsSessionsAuth,
      );
      features.paymentInstructionView = sessionHasScope(session, SharedServiceDomainScope.PaymentInstructionView);
      features.sdaAccountView = sessionHasScope(session, SharedServiceDomainScope.SdaAccountView);
      features.sdaExchangeRateView = sessionHasScope(session, SharedServiceDomainScope.SdaExchangeRateView);
      features.recipientCreate = sessionHasScope(session, SharedServiceDomainScope.RecipientCreate);
      features.sdaTransactionsView = sessionHasScope(session, SharedServiceDomainScope.SdaTransactionsView);
      features.sdaAccountsNameUpdate = sessionHasScope(session, SharedServiceDomainScope.SdaAccountsNameUpdate);

      // SAVINGS API
      features.savContractRead = sessionHasScope(session, SavingsScope.SavContractRead);
      features.sdaRequiredActionsWrite = sessionHasScope(session, SharedServiceDomainScope.SdaRequiredActionsWrite);
    }

    features.externalLoanManagement = Config.EXTERNAL_LOAN_MANAGEMENT;
    this.features.set({
      ...this.features.get(),
      ...features,
    });
  }

  /* The below function is needed because the scopes needed to be fusionned from two entry points : the client api and
     the Service Domain API. Otherwise, the existing scopes of either one of the apis would be overwritten by the others.
     In a near future, this process will be siplified by the use of only the client API ( cf vc-1050 ) */
  public updateFeatureWithServiceDomain(serviceDomainScopes: (SavingsScope | CreditScope)[]): void {
    this.features.set({
      ...this.features.get(),
      savContractRead: serviceDomainScopes.includes(SavingsScope.SavContractRead),
      savDeposit: serviceDomainScopes.includes(SavingsScope.SavDeposit),
    } as Features);
  }
}
